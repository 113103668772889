type Message = {
  type: string
  width?: number
  height?: number
  visible?: boolean
  redirectUrl?: string
  quoteUrl?: string
  data?: Record<any, any>
}

type Organization =
  | 'shipa'
  | 'icontainers'
  | 'b2bbrazil'
  | 'alibaba'
  | 'linc'
  | 'orion'
  | 'fastlogistics'
  | 'eximcargo'
  | 'airmenzies'

type Settings = {
  organization: Organization
  domain: string
  language?: 'en' | 'es' | 'pt' // default 'en'
  searchResultsInNewTab?: boolean // default false
  multiModeTabsPosition?: string
  defaultShipmentType?: 'LCL' | 'FCL' | 'AIR'
  details?: Record<any, any>
  isVertical?: boolean
}

interface Window {
  iframeWidget: (
    src: string,
    parent: HTMLElement | null,
    settings: Settings,
    onSubmit?: (quoteURL: string, data: Record<any, any>) => void
  ) => void
}

const query = {
  mobile: `(max-width: ${768 - 0.02}px)`,
  tablet: `(min-width: 768px) and (max-width: ${1200 - 0.02}px)`,
  desktop: `(min-width: 1200px)`,
}

const calcDeviceType = () => {
  const isMobile = window.matchMedia(query.mobile).matches
  const isTablet = window.matchMedia(query.tablet).matches
  const isDesktop = window.matchMedia(query.desktop).matches

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}

window.iframeWidget = (src, parent, settings, onSubmit) => {
  const {
    domain,
    organization,
    language = 'en',
    searchResultsInNewTab = false,
    multiModeTabsPosition = 'TOP',
    defaultShipmentType = 'FCL',
    details,
    isVertical = window.matchMedia(query.tablet).matches,
  } = settings

  const backdrop = document.createElement('div')
  backdrop.style.position = 'fixed'
  backdrop.style.zIndex = '1'
  backdrop.style.display = 'none'
  backdrop.style.width = '100%'
  backdrop.style.height = '100%'
  backdrop.style.right = '0'
  backdrop.style.bottom = '0'
  backdrop.style.top = '0'
  backdrop.style.left = '0'
  backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
  backdrop.onclick = () => {
    iframe.contentWindow?.postMessage({ type: 'backdrop', visible: false }, '*')
  }

  parent?.appendChild(backdrop)

  const queries: string[] = []
  queries.push(`organization=${organization}`)
  language && queries.push(`language=${language}`)
  searchResultsInNewTab && queries.push(`searchResultsInNewTab=${searchResultsInNewTab as unknown as string}`)
  domain && queries.push(`domain=${encodeURIComponent(domain)}`)
  multiModeTabsPosition && queries.push(`multiModeTabsPosition=${encodeURIComponent(multiModeTabsPosition)}`)
  defaultShipmentType && queries.push(`defaultShipmentType=${defaultShipmentType}`)
  details && queries.push(`details=${JSON.stringify(details)}`)
  isVertical && queries.push(`isVertical=1`)

  const { isMobile, isTablet, isDesktop } = calcDeviceType()
  queries.push(`isMobile=${isMobile as unknown as string}`)
  queries.push(`isTablet=${isTablet as unknown as string}`)
  queries.push(`isDesktop=${isDesktop as unknown as string}`)

  onSubmit && queries.push(`customOnSubmit=1`)

  const queryString = queries.length ? `?${queries.join('&')}` : ''

  const iframe = document.createElement('iframe')
  iframe.setAttribute('frameborder', '0')
  iframe.setAttribute('src', `${src}${queryString}`)
  iframe.setAttribute('width', '100%')
  iframe.style.position = 'relative'
  iframe.style.zIndex = '0'

  parent?.appendChild(iframe)

  const onResize = () => {
    const { isMobile, isTablet, isDesktop } = calcDeviceType()
    iframe.contentWindow?.postMessage({ type: 'resize', isMobile, isTablet, isDesktop }, '*')
  }

  window.addEventListener('resize', onResize)

  onResize()

  window.addEventListener('message', (event: MessageEvent<Message>) => {
    // Verify the message sender if needed
    if (!iframe || event.source !== iframe.contentWindow) return

    // Process the received message
    const message = event.data

    const { isMobile } = calcDeviceType()

    switch (message.type) {
      case 'orientation':
        if (parent && message.height) {
          parent.style.height = `${message.height}px`
          parent.style.position = 'relative'
        }
        break
      case 'size':
        if (message.width && message.height) {
          iframe.setAttribute('height', `${message.height}`)
        }
        if ((isVertical || window.matchMedia(query.mobile).matches) && parent && message.height) {
          parent.style.height = `${message.height}px`
        }
        break
      case 'backdrop':
        if (!isMobile) {
          backdrop.style.display = message.visible ? 'block' : 'none'
          iframe.style.zIndex = message.visible ? '99999' : '1'
        } else {
          backdrop.style.display = 'none'
        }
        break
      case 'slideout':
        if (message.visible) {
          iframe.style.position = 'fixed'
          iframe.style.zIndex = '99999'
          iframe.style.width = '100%'
          iframe.style.height = '100%'
          iframe.style.top = '0px'
          iframe.style.left = '0px'
        } else {
          iframe.removeAttribute('style')
          iframe.style.position = 'relative'
          iframe.style.zIndex = '1'
        }
        break
      case 'redirect':
        if (!message.redirectUrl) return

        if (searchResultsInNewTab) {
          window.open(message.redirectUrl, '_blank')
        } else {
          window.location.href = message.redirectUrl
        }
        break
      case 'submit':
        if (message.quoteUrl && message.data) {
          onSubmit?.(message.quoteUrl, message.data)
        }
        break
    }
  })
}
